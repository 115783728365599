import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Spinner } from "@chakra-ui/react";
import google from "./assets/svg/google.svg";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  GoogleAuthProvider,
} from "firebase/auth";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { Link } from "react-router-dom";

import {
  loginSelector,
  firebaseGoogleLogin,
  getGoogleReturnResult,
  onPostLogin,
} from "./reducers/loginSlice";

const Login = () => {
  const dispatch = useDispatch();
  // const toast = useToast();

  const {
    isLoadingGoogleStatus,
  } = useSelector(loginSelector);

  const [loading, setLoading] = useState(false);
  const [setMessage] = useState(null);

  const [value, setValue] = useState();
  const [valid, setValid] = useState(true);
  const [otp, setOtp] = useState({
    value: null,
    isOtp: false,
  });

  const onChange = (data) => {
    setValue(data);
    setValid(false);
  };

  const onHandleLogin = (data) => {
    dispatch(onPostLogin(data))
      .then((result) => {
        window.localStorage.setItem("8pm", JSON.stringify(result));
        window.location.replace("/dashboard");
      })
      .catch((err) => {
        console.log("the error inside of the login page", err);
      });
  };
  

  useEffect(() => {
    const firebase_login = window.localStorage.getItem("firebase_login");
    if (!firebase_login) {
      return;
    }
    if (firebase_login !== null) {
      setLoading(true);
    }
    console.log("i am inside the useEffect");
    dispatch(getGoogleReturnResult())
      .then((data) => {
        console.log("i am inside the .then of return result");
        window.localStorage.removeItem("firebase_login");
        setLoading(false);
        const credential = GoogleAuthProvider.credentialFromResult(data);
        const userData = {
          id_token: credential.idToken,
        };
        onHandleLogin(userData);
      })
      .catch((err) => {
        console.log("i am", err);
        console.log("error response", err.response);
        setMessage(err.message);
        setLoading(false);
        localStorage.removeItem("firebase_login");
      });
  }, [dispatch, onHandleLogin, setMessage]);



  const loginWithGoogle = () => {
    window.localStorage.setItem("firebase_login", true);
    dispatch(firebaseGoogleLogin());
  };




  const onSignInSubmit = (e) => {
    e.preventDefault();
    console.log("i am in sign in submit method");
    const appVerifier = window.recaptchaVerifier;
    const auth = getAuth();
    signInWithPhoneNumber(auth, value, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        console.log("the confirmationResult", confirmationResult);
        setOtp({
          ...otp,
          isOtp: true,
        });
      })
      .catch((err) => {
        console.log(err);
        setOtp({
          ...otp,
          isOtp: false,
        });
      });
  };


  useEffect(() => {
    const configureCaptcha = () => {
      console.log("i am hre");
      const auth = getAuth();
      auth.languageCode = "it";
      window.recaptchaVerifier = new RecaptchaVerifier(
        "sign-in-button",
       
        {
          size: "invisible",
          callback: (response) => {
            console.log("recapta verified", response);
            onSignInSubmit();
          },
          defaultCountry: "US"
        },
        auth
      );
    };

    configureCaptcha();
  }, [onSignInSubmit]);




  const onSubmitOTP = (e) => {
    e.preventDefault();
    if (!otp.value) return;

    window.confirmationResult
      .confirm(otp.value)
      .then((result) => {
        // User signed in successfully.
        const user = result.user;
        console.log("the returned data after singing with otp", user.accessToken);
        const data = {
          id_token: user.accessToken
        }
        console.log(data)
        onHandleLogin(data)
        // ...
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onChangeOtp = (e) => {
    setOtp({
      ...otp,
      value: e.target.value
    })
  }


  return (
    <div className="login__wrapper">
      <div className="login__wrapper--form">
        <span>Welcome back</span>
        <h1>{otp.isOtp ? "Enter OTP!" : "Log into your account"}</h1>

        <form className="input__wrapper">
          {!otp.isOtp ? (
            <>
              <div className="">
                <div className="google-login" onClick={() => loginWithGoogle()}>
                  <p style={{ marginRight: 10 }}>
                    {(loading || isLoadingGoogleStatus) && <Spinner />}
                  </p>
                  <img className="google" src={google} alt="" />
                  <p className="google-text">Login with Google</p>
                </div>
              </div>
              <div className="or__container">
                <span>Or</span>
              </div>
              <PhoneInput
                placeholder="8pm phone number"
                value={value}
                defaultCountry="NG"
                onChange={onChange}
                className={valid ? "" : "error"}
              />
              {valid ? null : (
                <span style={{ marginTop: 8, color: "red" }}>
                  Provide a valid phone number
                </span>
              )}
              <button id="sign-in-button" onClick={onSignInSubmit}>
                Log in with phone
              </button>{" "}
            </>
          ) : (
            <>
              <input type="number" name="otp" onChange={onChangeOtp} />
              <button id="sign-in-button" onClick={onSubmitOTP}>
                Complete Login
              </button>
            </>
          )}
        </form>
      </div>
      <div className="quick__links">
        <Link to="/faq">Help</Link>
        <Link to="/contact">Support</Link>
        <Link to="/terms">Terms and Conditions</Link>
        <Link to="/privacy">Privacy policy</Link>
      </div>
    </div>
  );
};

export default Login;
