import { createSlice } from "@reduxjs/toolkit";
import app from '../firebase'
import {
  getAuth,
  GoogleAuthProvider,
  signInWithRedirect,
  getRedirectResult,
} from "firebase/auth";

import { baseUrl, url } from "../utils/Endpoints";
import API from "../utils/http.serviceWithToken";

const auth = getAuth();
const provider = new GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });

const initialState = {
  user: {},
  loading: false,
  error: false,
  success: false,
  status: false,
  message: "",

  isLoadingGoogleStatus: false,
  isLoadingGoogleStatusSuccess: false,
  isLoadingGoogleErrorMessage: null,
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    user: (state, { payload }) => {
      state.user = payload;
      state.status = "success";
    },
    fetchingUsers: (state, { payload }) => {
      state.status = "loading";
    },
    fetchingUserError: (state, { payload }) => {
      state.status = "failed";
      state.message = payload;
    },

    onGoogleAuthenticate: (state, { payload }) => {
      state.isLoadingGoogleStatus = true;
    },
    onGoogleAuthenticateSuccess: (state, { payload }) => {
      state.isLoadingGoogleStatus = false;
      state.isLoadingGoogleStatusSuccess = true;
    },
    onGoogleAuthenticateError: (state, { payload }) => {
      state.isLoadingGoogleStatus = false;
      state.isLoadingGoogleErrorMessage = payload;
    },
  },
});

export const loginSelector = (state) => state.loginReducer;

export const {
  login,
  onGoogleAuthenticate,
  onGoogleAuthenticateSuccess,
  onGoogleAuthenticateError,

  user,
  fetchingUserError,
  fetchingUsers
} = loginSlice.actions;

export const firebaseGoogleLogin = () => {
  console.log("i am in firebase login");
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      signInWithRedirect(auth, provider)
        .then((result) => {
          console.log("firebase signin with redirect finshined");
        })
        .catch((err) => {
          dispatch(onGoogleAuthenticateError(err.message));
          console.log(err.message);
        });
    });
  };
};

export const getGoogleReturnResult = () => {
  console.log("i am inside the getGoogleReturnResult");
  localStorage.removeItem("firebase_login");
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log("i am inside the return new promise");
      getRedirectResult(auth)
        .then((result) => {
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;
          const idToken = credential.idToken;
          console.log("the access token",token);
          console.log("the id Token", idToken);
          dispatch(onGoogleAuthenticateSuccess());
          resolve(result);
        })
        .catch((err) => {
          dispatch(onGoogleAuthenticateError());
          reject(err);
          localStorage.removeItem("firebase_login");
        });
    });
  };
};

export const onPostLogin = (data) => {
  return (dispatch) => {
    dispatch(fetchingUsers());
    return new Promise((resolve, reject) => {
      API({
        url: `${baseUrl}/${url.login}`,
        method: "POST",
        data,
      })
        .then(({ data }) => {
          console.log("this is the result", data);
          dispatch(user(data));
          return resolve(data);
        })
        .catch((err) => {
          dispatch(fetchingUserError(err?.message));
          reject(err);
        });
    });
  };
};

export default loginSlice.reducer;
