import React from "react";
import { Link, NavLink, useRouteMatch } from "react-router-dom";
import Footer from "./Footer";

const Premium = () => {
  const match = useRouteMatch();
  return (
    <div className="premium__wrapper">
      <header>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="masthead__navigation">
                <h1>
                  <img
                    src="https://res.cloudinary.com/dotvhpvra/image/upload/v1620398746/8PM_gradient_app_icon2_sotwyc.png"
                    alt=""
                  />
                </h1>
                <div className="d-flex align-items-center">
                  <Link to={`/#about`}>About 8pm</Link>
                  <NavLink to={`${match.path}`} activeClassName="active__link">
                    Premium
                  </NavLink>
                  <Link to={`/#discover`}>Discover</Link>
                  <Link to={`/#match`}>Match</Link>
                  <NavLink to="/login" activeClassName="active__link">
                    Login
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <section className="premium__header">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 d-flex flex-column justify-content-center details">
              <h1>Dating and hook-up without limits</h1>
              <p>Find the Perfect soul for you today without any restriction</p>

              <Link to={`${match.path}/pay`} className="button">
                Join now for $2
              </Link>
            </div>
          </div>
        </div>
      </section>
      <PremiumContainer />
      <Footer />
    </div>
  );
};

const PremiumContainer = () => {
  const match = useRouteMatch();
  return (
    <div className="premium__reasons">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h1>Why go premium?</h1>
          </div>
        </div>
        <div className="row card__options">
          <div className="col-sm-3">
            <div className="premium__card d-flex flex-column justify-content-center align-items-center">
              <div className="card__value one"></div>
              <h6>Unlimited Date</h6>
              <p>Meet anyone and any time</p>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="premium__card d-flex flex-column justify-content-center align-items-center">
              <div className="card__value one"></div>
              <h6>Unlimited Chats</h6>
              <p>Chats and send images to everyone</p>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="premium__card d-flex flex-column justify-content-center align-items-center">
              <div className="card__value one"></div>
              <h6>Unlimited Profile view</h6>
              <p>View profiles of any one you like</p>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="premium__card d-flex flex-column justify-content-center align-items-center">
              <div className="card__value one"></div>
              <h6>Unlimited hook-up</h6>
              <p>Meet anyone and hook up</p>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center  d-flex justify-content-center align-items-center">
            <Link to={`${match.path}/pay`} className="button">
              Join now for $2
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Premium;
