import React from "react";
import MeetPhoto4 from "./assets/meetphoto4.jpeg";
import MeetPhoto2 from "./assets/meetphoto2.jpeg";
import MeetPhoto1 from "./assets/meetphoto3.jpeg";
import MeetPhoto3 from "./assets/meetphoto1.jpeg";

const Match = () => {
  return (
    <div className="match__container" id="match">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h4>Take a look at some of our users</h4>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-sm-3 col-md-6 col-lg-3 mt-3">
            <div className="match__card">
              <img src={MeetPhoto3} alt="" />
              <h6>Alex starnes</h6>
              <h5>Beauty and brains</h5>
              <p>
                I am a fun and lovely person to be with, i am beautiful and
                smart also, love to meet new people and make new friends, love
                music
              </p>
            </div>
          </div>
          <div className="col-sm-3 col-md-6 col-lg-3 mt-3">
            <div className="match__card">
              <img src={MeetPhoto2} alt="" />
              <h6>Patricia Stevens</h6>
              <h5>Yes, i'm always smiling</h5>
              <p>
                I am always smiling. i am lovable looking for a serious
                relationship and someone i can also spoil with love and
                affection
              </p>
            </div>
          </div>
          <div className="col-sm-3 col-md-6 col-lg-3 mt-3">
            <div className="match__card">
              <img src={MeetPhoto1} alt="" />
              <h6>Leon Eli</h6>
              <h5>Looking for hook-ups</h5>
              <p>
                I am ready to take you out anytime anyday to go clubbing, but i
                am only looking for ladies that wants to hookup
              </p>
            </div>
          </div>
          <div className="col-sm-3 col-md-6 col-lg-3 mt-3">
            <div className="match__card">
              <img src={MeetPhoto4} alt="" />
              <h6>Kaysha Daniel</h6>
              <h5>Looking for my soulmate</h5>
              <p>
                I am looking for a loving lady, who complements me, feminine and
                beautiful to spend the rest of my life with
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Match;
