import { createSlice } from "@reduxjs/toolkit";
// import { baseUrl, url } from "../utils/Endpoints";

import API from "../utils/http.serviceWithToken";

const initialState = {
  data: {},
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: null,
};

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    initializing: (state, { payload }) => {
      state.isLoading = true;
    },
    payment: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    paymentFailed: (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
      state.message = payload;
    },
  },
});

export const subscriptionSelector = (state) => state.subscriptionReducer;

export const { subscription, initializing, payment, paymentFailed } =
  subscriptionSlice.actions;

export const initializePayment = () => {
  return (dispatch) => {
    dispatch(initializing());
    return new Promise((resolve, reject) => {
      API({
        url: "https://api.8pm.ng/subscription/pay",
        method: "POST",
      })
        .then(({ data }) => {
          console.log("the payment data", data);
          dispatch(payment(data));
          return resolve(data);
        })
        .catch((err) => {
          dispatch(paymentFailed(err?.message));
          return reject(err);
        });
    });
  };
};


export default subscriptionSlice.reducer