import { configureStore, applyMiddleware } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import thunk from "redux-thunk";

import loginSlice from "./reducers/loginSlice";
import subscriptionSlice from "./reducers/subscriptionSlice";

const middlewares = applyMiddleware(thunk);

const reducers = combineReducers({
  loginReducer: loginSlice,
  subscriptionReducer: subscriptionSlice,
});

const persistConfig = {
  key: "8pm_root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middlewares,
});

export default store;
