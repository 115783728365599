import React from "react";
import Scrollspy from "react-scrollspy";
import { Helmet } from 'react-helmet'
import Footer from "./Footer";

const Contact = () => {
  return (
    <div>
      <div className="privacy__masthead">
        <Helmet>
          <title>8pm - Contact Us</title>
        </Helmet>
        <header>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="masthead__navigation">
                  <img
                    src="https://res.cloudinary.com/dotvhpvra/image/upload/v1620398746/8PM_gradient_app_icon2_sotwyc.png"
                    alt=""
                  />

                  <div className="d-flex align-items-center">
                    <Scrollspy items={["about", "discover", "match"]}>
                      <a href="/#about">About 8pm</a>
                      <a href="/#discover">Discover</a>
                      <a href="/#match">Match</a>
                    </Scrollspy>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="privacy__masthead--text">
          <h1>Contact us</h1>
        </div>
      </div>
      <section className="privacy--section">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h3>Contact us</h3>
              <p>
                Got something you want to talk about? Contact us or email us and
                we promise to get back to you as soon as we can.
              </p>
            </div>
            <div className="col-sm-12">
              <h3>Help / Support</h3>
              <p>
                For all things technical and app-related.{" "}
                <a href="mailto:support@8pm.ng">support@8pm.ng</a>
              </p>
            </div>
            <div className="col-sm-12">
              <h3>Partnerships</h3>
              <p>
                Interested in partnering with 8pm{" "}
                <a href="mailto:partners@8pm.ng">partners@8pm.ng</a>
              </p>
            </div>
            <div className="col-sm-12">
              <h3>Success Stories</h3>
              <p>
                Did you meet the most important person in your life on 8pm?
                Tell us about it <br />
                <a href="mailto:8pmstories@8pm.ng">8pmstories@8pm.ng</a>
              </p>

              <p>
                You may view our terms and conditions here{" "}
                <a href="terms">terms and conditions</a> <br />
                and also read about our privacy policy here{" "}
                <a href="privacy">privacy policy</a>
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Contact;
