import React from "react";
import Nav from "./Nav";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Tag, Button } from "@chakra-ui/react";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { loginSelector } from "./reducers/loginSlice";
import {
  subscriptionSelector,
  initializePayment,
} from "./reducers/subscriptionSlice";

import "./dashboard.styles.css";
import { Spinner } from "@chakra-ui/spinner";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { user, loading, } = useSelector(loginSelector);
  const {isLoading } =
    useSelector(subscriptionSelector);

  console.log(user.data.user);

  const onHandlePayment = () => {
    dispatch(initializePayment()).then((data) => {
      toast.success(data.message);
      setTimeout(() => {
        window.location.replace(data.data.authorization_url);
      }, 5000);
    });
  };
  return (
    <div className="dashboard__wrapper d-flex justify-content-between">
      <Nav />
      <ToastContainer position="top-right" autoClose={5000} />
      <div className="dashboard__content">
        <div className="dashboard__content-profile">
          {loading && (
            <div className="loader">
              <Spinner />
              <h5>Fetching user profile</h5>
            </div>
          )}
          {!loading && user && user && (
            <>
              <div className="header">
                <Avatar
                  name="Dan Abrahmov"
                  src={user.data.user.profile_image.url}
                  size="2xl"
                />
                <div
                  className="d-flex justify-content-between biodata"
                  style={{ width: 300 }}
                >
                  <h5>
                    <b>Gender:</b> {user.data.user.gender}
                  </h5>
                  <h5>
                    <b>Age:</b> {user.data.user.age}
                  </h5>
                  <h5>
                    <b>Height:</b> {user.data.user.height}
                  </h5>
                </div>
              </div>
              <div className="profile__details mt-5">
                <h5>Full name</h5>
                <p>{user.data.user.name}</p>
              </div>

              <div className="profile__details mt-4">
                <h5>About</h5>
                <p>{user.data.user.about}</p>
              </div>
              <div className="profile__details mt-4">
                <h5>Interest</h5>
                <div className="tag__list d-flex justify-content-between flex-wrap">
                  {Object.keys(user.data.user.interests).map((interest) => {
                    return (
                      <Tag key={interest} style={{ marginTop: 8 }}>
                        {user.data.user.interests[interest]}
                      </Tag>
                    );
                  })}
                </div>
              </div>
              <div className="profile__details mt-4">
                <h5>Looking for</h5>
                <p>{user.data.user.looking_for_gender}</p>
              </div>
              <div className="profile__details mt-4 d-flex align-items-center justify-content-center">
                {user.data.user.subscription.active ? (
                  <Button size="lg">Cancel Subscription</Button>
                ) : (
                  <Button
                    size="lg"
                    background="#ffcc00"
                    fontFamily="Avenir next Demi"
                    onClick={onHandlePayment}
                    isLoading={isLoading}
                  >
                    Join Premium
                  </Button>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
