// import * as firebase from "firebase/app"
// import "firebase/auth"

// const firebaseConfig = {
//   apiKey: "AIzaSyDnoPn_ZhblulnM5NA2LAYNrd1JlQytz-g",
//   authDomain: "pm-app-159fa.firebaseapp.com",
//   projectId: "pm-app-159fa",
//   storageBucket: "pm-app-159fa.appspot.com",
//   messagingSenderId: "41732365868",
//   appId: "1:41732365868:web:25eec978e947db75d6fb89",
//   measurementId: "G-9679PB0FWF"
// };

// // Initialize Firebase
// firebase.default.initializeApp(firebaseConfig);



// export const auth = firebase.default.auth();

// export const provider = new firebase.default.auth.PhoneAuthProvider();
// provider.setCustomParameters({ prompt: "select_account" });



// export default firebase;






// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDnoPn_ZhblulnM5NA2LAYNrd1JlQytz-g",
  authDomain: "pm-app-159fa.firebaseapp.com",
  projectId: "pm-app-159fa",
  storageBucket: "pm-app-159fa.appspot.com",
  messagingSenderId: "41732365868",
  appId: "1:41732365868:web:25eec978e947db75d6fb89",
  measurementId: "G-9679PB0FWF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default app;
