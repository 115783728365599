import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect,
} from "react-router-dom";
import { useSelector } from "react-redux";
import "./App.css";
import Contact from "./Contact";
import Dashboard from "./Dashboard";
// import Faq from "./Faq";
import Home from "./Home";
import Login from "./Login";
import Premium from "./Premium";
import Privacy from "./privacy";
import Terms from "./terms";

import { loginSelector } from "./reducers/loginSlice";

function App() {
  const { user } = useSelector(loginSelector);
  return (
    <Router>
      <Switch>
        <Route path="/terms" exact={true}>
          <Terms />
        </Route>
        <Route path="/privacy" exact={true}>
          <Privacy />
        </Route>
        <Route path="/contact-us" exact>
          <Contact />
        </Route>
        <Route path="/premium" exact>
          <Premium />
        </Route>
        <Route path="/login" exact>
          {user?.data?.auth?.token ? <Redirect to="/dashboard" /> : <Login />}
        </Route>
        <Route path="/dashboard" exact={false}>
          <Dashboard />
        </Route>
        {/* <Route path="/faq" exact={true}>
          <Faq />
        </Route> */}
        <Route path="/" exact={false}>
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
