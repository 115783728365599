import axios from "axios";
import * as endpoints from "./Endpoints";

let token = "";
const jwt = JSON.parse(window.localStorage.getItem("8pm"));
if (!jwt) {
  token = "";
} else {
  token = jwt.data.auth.token;

}

const API = axios.create({
  baseURL: endpoints.baseUrl,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

API.interceptors.response.use(
  (response) =>
    new Promise((resolve, reject) => {
      resolve(response);
    }),

  (error) => {
    if (!error.response) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    if (error.response.status === 401) {
      localStorage.clear();
      window.location.replace('/login');
    } else {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  }
);


export default API;