import React from "react";

import Discovery from "./Discovery";
import Marketing from "./Marketing";
import Masthead from "./Masthead";
import Match from "./Match";
import Footer from "./Footer";


const Home = () => {



  return (
    <div className="main__wrapper">
      <Masthead/>
      <Marketing />
      <Discovery />
      <Match />
      <Footer />
    </div>
  );
};

export default Home;
