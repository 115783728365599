import React from 'react';

const Nav = () => {
  return (
    <nav className="dashboard__navigation">
      
    </nav>
  )
}

export default Nav;